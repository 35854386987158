import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
}) // not root scope
export class BaseClass implements OnDestroy {
  public destroyed$ = new Subject<void>();
  platformId: Object;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.platformId = platformId;
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  checkPlatform(): any {
    return isPlatformBrowser(this.platformId)
  }
  reMediaIf(size: any = 992, mode: any = 'lt') {
    if (this.checkPlatform()) {
      const innerWidth = window.innerWidth;
      const shouldHide = (mode === 'gt' && innerWidth >= size) || (mode === 'lt' && innerWidth <= size - 1)
      if (shouldHide) return true;
      else return false
    }
    return false
  }

}
